import React from 'react'
import { IRS_CORNER } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import IMGEDU from '../../images/new/hedu.jpeg'
const HigherEducation = () => {
  return (
    <div>
      <Layout>
        <SEO title="Higher Education" />
        <PageStructure
          image={IMGEDU}
          title="Higher Education"
          desc="TESTIG"
          mode={IRS_CORNER}
        />
      </Layout>
    </div>
  )
}

export default HigherEducation
